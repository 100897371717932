define("ember-service-worker-update-notify/services/service-worker-update-notify", ["exports", "ember", "@ember/service", "@ember/object/evented", "@ember/application", "@ember/object", "ember-concurrency", "ember-service-worker-update-notify/utils/service-worker-has-update"], function (_exports, _ember, _service, _evented, _application, _object, _emberConcurrency, _serviceWorkerHasUpdate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const configKey = 'ember-service-worker-update-notify';
  async function update() {
    const reg = await navigator.serviceWorker.register('/sw.js', {
      scope: '/'
    });
    return reg.update();
  }
  var _default = _service.default.extend(_evented.default, {
    hasUpdate: false,
    pollingInterval: (0, _object.computed)(function () {
      let config = (0, _application.getOwner)(this).resolveRegistration('config:environment')[configKey];
      return config && config.pollingInterval || 120000;
    }),
    pollingTask: (0, _emberConcurrency.task)(function* () {
      while (true) {
        yield update();
        yield (0, _emberConcurrency.timeout)(this.pollingInterval);
      }
    }),
    _attachUpdateHandler() {
      (0, _serviceWorkerHasUpdate.default)().then(hasUpdate => {
        this.pollingTask.cancelAll();
        if (hasUpdate) {
          this.set('hasUpdate', true);
          this.trigger('update');
        }
      });
    },
    init() {
      this._super(...arguments);
      if (typeof FastBoot === 'undefined') {
        this._attachUpdateHandler();
        if (!_ember.default.testing) {
          this.pollingTask.perform();
        }
      }
    }
  });
  _exports.default = _default;
});