define("ember-svg-jar/inlined/chevron-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#AAB3BB\" fill-rule=\"nonzero\" d=\"M4.986 5.121l-3.279 3.28L.293 6.985l4.693-4.693L9.664 6.97 8.25 8.385z\"/>",
    "attrs": {
      "width": "10",
      "height": "10",
      "viewBox": "0 0 10 10",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});