define("ember-svg-jar/inlined/check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.23 1.288a2 2 0 013.023 2.615l-.113.13-7.19 7.622a2 2 0 01-2.586.277l-.138-.104-4.81-3.948a2 2 0 012.408-3.19l.13.098L7.32 7.551l5.91-6.263z\" fill=\"currentColor\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "17",
      "height": "13",
      "viewBox": "0 0 17 13",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});