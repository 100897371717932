define("ember-service-worker-update-notify/templates/components/service-worker-update-notify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "sRq5DMMf",
    "block": "[[[41,[30,0,[\"hasUpdate\"]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "ember-service-worker-update-notify/templates/components/service-worker-update-notify.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});