define("ember-body-class/mixins/body-class", ["exports", "@ember/object/mixin", "@ember/application", "@ember/service", "ember-body-class/util/bodyClass"], function (_exports, _mixin, _application, _service, _bodyClass) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-new-mixins, prettier/prettier */
  var _default = _mixin.default.create({
    router: (0, _service.inject)(),
    actions: {
      loading(transition) {
        const document = (0, _application.getOwner)(this).lookup('service:-document');
        const body = document.body;
        (0, _bodyClass.addClass)(body, 'loading');
        transition.finally(function () {
          (0, _bodyClass.removeClass)(body, 'loading');
        });
        return true;
      },
      error: function /* error, transition */
      () {
        const document = (0, _application.getOwner)(this).lookup('service:-document');
        const body = document.body;
        (0, _bodyClass.addClass)(body, 'error');
        this.router.on('routeDidChange', () => {
          if (this.router.currentRouteName !== 'error') {
            (0, _bodyClass.removeClass)(body, 'error');
          }
        });
        return true;
      }
    }
  });
  _exports.default = _default;
});