define("ember-svg-jar/inlined/download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.977 7.398c.979-.979.66-1.773-.727-1.773h-2V1.248C11.25.558 10.698 0 9.996 0H8.004C7.31 0 6.75.56 6.75 1.248v4.377h-2c-1.38 0-1.712.788-.727 1.773l4.095 4.095c.487.487 1.284.48 1.764 0l4.095-4.095zM1.25 14.625A1.25 1.25 0 000 15.882v.861C0 17.437.558 18 1.251 18H16.75A1.25 1.25 0 0018 16.743v-.861c0-.694-.558-1.257-1.251-1.257H1.25z\" fill=\"currentColor\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});