define("ember-svg-jar/inlined/colorwheel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\"><path fill=\"#E6332A\" d=\"M123 123L51 23.497C72.862 7.482 96.033 0 123 0v123z\"/><path fill=\"#A3195B\" d=\"M123 123L6 85.124C14.413 59.394 28.743 39.804 50.697 24L123 123z\"/><path fill=\"#662483\" d=\"M123 123.066L6.288 161c-8.384-25.858-8.384-50.142 0-76L123 123.066z\"/><path fill=\"#1D71B8\" d=\"M123 123l-72.303 99C28.743 206.066 14.413 186.475 6 160.876L123 123z\"/><path fill=\"#36A9E1\" d=\"M123 123v123c-27.098 0-50.138-7.482-72-23.497L123 123z\"/><path fill=\"#00A19A\" d=\"M123 123l72 99.503C173.138 238.518 149.967 246 123 246V123z\"/><path fill=\"#063\" d=\"M123 123l117 37.795c-8.404 25.764-22.717 45.38-44.646 61.205L123 123z\"/><path fill=\"#95C11F\" d=\"M123 122.934L239.712 85c8.384 25.858 8.384 50.142 0 76L123 122.934z\"/><path fill=\"#FCEA10\" d=\"M123 123l72.303-99C217.257 39.934 231.587 59.525 240 85.124L123 123z\"/><path fill=\"#F39200\" d=\"M123 123V0c27.098 0 50.138 7.482 72 23.497L123 123z\"/></g>",
    "attrs": {
      "width": "246",
      "height": "246",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});