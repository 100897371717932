define("ember-svg-jar/inlined/arrow-right-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17.92 11.62a1 1 0 00-.21-.33l-5-5a1.003 1.003 0 10-1.42 1.42l3.3 3.29H7a1 1 0 100 2h7.59l-3.3 3.29a1 1 0 000 1.42 1.002 1.002 0 001.42 0l5-5c.091-.095.162-.207.21-.33a1 1 0 000-.76z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});