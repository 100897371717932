define("ember-svg-jar/inlined/profile-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.06 25.849a6 6 0 1111.88 0 11.43 11.43 0 01-3.194 1.321 3.5 3.5 0 10-5.493 0 11.43 11.43 0 01-3.193-1.321z\" fill=\"#E1E6EA\"/><path opacity=\".3\" d=\"M9 25.125V25a7 7 0 1114 0v.125c-.472.363-.974.69-1.5.977V25a5.5 5.5 0 10-11 0v1.102a11.512 11.512 0 01-1.5-.977z\" fill=\"#D9D9D9\"/><path d=\"M9 25.125V25a7 7 0 1114 0v.125c-.472.363-.974.69-1.5.977V25a5.5 5.5 0 10-11 0v1.102a11.512 11.512 0 01-1.5-.977z\" fill=\"#C4CDD6\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16 26.5c5.799 0 10.5-4.701 10.5-10.5S21.799 5.5 16 5.5 5.5 10.201 5.5 16 10.201 26.5 16 26.5zm0 1.5c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12z\" fill=\"#C4CDD6\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16 15.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm0 1.5a4 4 0 100-8 4 4 0 000 8z\" fill=\"#FFCB55\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});