define("ember-svg-jar/inlined/registration-send", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><rect width=\"65.185\" height=\"65.455\" rx=\"32.593\" fill=\"#FFF\"/><rect stroke=\"#1D3C57\" stroke-width=\"1.688\" x=\".844\" y=\".844\" width=\"63.498\" height=\"63.767\" rx=\"31.749\"/><path d=\"M64.61 3.557c.381.457.459.958.233 1.504L51.28 36.372c-.173.394-.479.656-.916.788a1.334 1.334 0 01-.699.007 1.462 1.462 0 01-.481-.233l-8.607-6.325-6.686 4.996c-.335.263-.722.34-1.16.23a1.174 1.174 0 01-.444-.2 1.37 1.37 0 01-.52-.661 1.372 1.372 0 01-.05-.837l1.85-7.421 23.901-17.96-27.53 14.029-7.5-5.53c-.474-.328-.659-.789-.556-1.38.113-.574.443-.936.99-1.086L63.182 3.156a1.33 1.33 0 01.725-.023c.282.07.517.212.704.424z\" fill=\"#50ADF7\" fill-rule=\"nonzero\"/><path stroke=\"#AACBD8\" stroke-width=\"2.25\" stroke-linecap=\"round\" d=\"M28.814 37.432l-9.258 8.026M42.666 39.068l-8.444 12.066M27.184 25.977l-12.795 7.299\"/></g>",
    "attrs": {
      "width": "66",
      "height": "66",
      "viewBox": "0 0 66 66",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});