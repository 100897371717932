define("ember-svg-jar/inlined/external-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M1.9 3.325a1 1 0 01.993.883l.007.117v5a1 1 0 00.883.993l.117.007h5a1 1 0 01.116 1.993l-.116.007h-5A3 3 0 01.905 9.5L.9 9.325v-5a1 1 0 011-1zM12.186.315a1 1 0 01.382.084.94.94 0 01.537.537 1 1 0 01.085.382v5.657a1.004 1.004 0 11-2.008 0l.007-3.246-4.66 4.66a1 1 0 11-1.414-1.414l4.66-4.66-3.246.007a1.004 1.004 0 110-2.008z\" fill=\"#50ADF7\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "14",
      "height": "13",
      "viewBox": "0 0 14 13",
      "xmlns": "http://www.w3.org/2000/svg",
      "class": "specification-text-card__svg"
    }
  };
  _exports.default = _default;
});