define("ember-svg-jar/inlined/favorite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M1.21 8.94C.275 8.267-.12 7.692.032 7.214c.151-.478.805-.717 1.963-.717h4.358l1.33-4.01v-.019c.24-.717.52-1.202.84-1.457.32-.255.64-.255.958 0 .317.255.596.74.835 1.457l.01.02 1.32 4.009h4.358c.755 0 1.302.11 1.642.33.34.22.436.517.288.892-.148.374-.53.781-1.146 1.221l-.095.076-3.415 2.443 1.311 4 .029.038c.352 1.094.324 1.794-.085 2.1-.41.304-1.082.12-2.02-.553l-.056-.037-.047-.029-3.415-2.453-3.462 2.482-.038.037c-.937.673-1.613.857-2.029.552-.415-.305-.44-1.005-.075-2.099l.01-.019v-.028l1.31-3.99-3.414-2.444-.038-.038-.047-.038z\" fill=\"#FFCB55\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});