define("ember-svg-jar/inlined/close-rounded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M.32.32a1.09 1.09 0 000 1.542L4.457 6 .32 10.138l-.091.103a1.091 1.091 0 001.633 1.44L6 7.543l4.138 4.138.103.09a1.091 1.091 0 001.44-1.633L7.543 6l4.138-4.138.09-.102A1.091 1.091 0 0010.138.32L6 4.457 1.862.32 1.76.229a1.091 1.091 0 00-1.44.09z\" fill=\"currentcolor\"/>",
    "attrs": {
      "viewBox": "0 0 12 12",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});