define("ember-iframe-resizer-modifier/modifiers/iframe-resizer", ["exports", "ember-modifier", "iframe-resizer/js/iframeResizer"], function (_exports, _emberModifier, _iframeResizer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class IframeResizerModifier extends _emberModifier.default {
    get iframeResizerOptions() {
      const allPositionalArgs = this.args.positional.reduce((acc, current) => {
        return {
          ...acc,
          ...current
        };
      }, {});
      return {
        ...allPositionalArgs,
        ...this.args.named
      };
    }
    didReceiveArguments() {
      (0, _iframeResizer.default)(this.iframeResizerOptions, this.element);
    }
    willRemove() {
      this.element.iFrameResizer.removeListeners();
    }
  }
  _exports.default = IframeResizerModifier;
});