define("ember-svg-jar/inlined/arrow-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" fill-rule=\"nonzero\" d=\"M0 7.942L3.435 4.5 0 1.057 1.058 0l4.5 4.5-4.5 4.5z\"/>",
    "attrs": {
      "width": "6",
      "height": "9",
      "viewBox": "0 0 6 9",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});