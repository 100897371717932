define("ember-svg-jar/inlined/chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5.8 11.2V5H2C.9 5 0 5.9 0 7v6c0 1.1.9 2 2 2h1v3l3-3h5c1.1 0 2-.9 2-2v-1.82a.943.943 0 01-.2.021h-7V11.2zM18 0H9C7.9 0 7 .9 7 2v8h7l3 3v-3h1c1.1 0 2-.899 2-2V2c0-1.1-.9-2-2-2z\" fill=\"currentColor\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "20",
      "height": "18",
      "viewBox": "0 0 20 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});