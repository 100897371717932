define("ember-svg-jar/inlined/pencil", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 10.292V13h2.708l7.987-7.987-2.708-2.708L0 10.292zm12.789-7.373a.72.72 0 000-1.018l-1.69-1.69a.72.72 0 00-1.018 0L8.759 1.533l2.708 2.708 1.322-1.322z\" fill=\"#A8D6FB\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "13",
      "height": "13",
      "viewBox": "0 0 13 13",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});