define("ember-svg-jar/inlined/close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"currentColor\" fill-rule=\"nonzero\"><path d=\"M.352 1.208l.84-.856 10.35 10.163-.84.856z\"/><path d=\"M10.796.351l.853.845L1.204 11.734l-.853-.845z\"/></g>",
    "attrs": {
      "width": "12",
      "height": "12",
      "viewBox": "0 0 12 12",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});