define("ember-svg-jar/inlined/customers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M4.63 8.13c1.471 0 4.372.833 4.557 2.494l.01.17v1.902H.063v-1.903C.063 9.02 3.11 8.13 4.63 8.13zm5.708-1.142c1.468 0 4.359.83 4.556 2.483l.01.18v1.903h-4.566v-.76c0-1.47-1.173-2.578-2.693-3.21.916-.397 1.975-.596 2.693-.596zM4.634 2.42c1.265 0 2.28 1.02 2.28 2.283a2.276 2.276 0 01-2.28 2.284 2.282 2.282 0 110-4.567zm5.708-1.141c1.266 0 2.28 1.02 2.28 2.283a2.276 2.276 0 01-2.28 2.283 2.282 2.282 0 110-4.566z\" fill=\"currentColor\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "15",
      "height": "15",
      "viewBox": "0 0 15 15",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});