define("ember-svg-jar/inlined/play", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#FFF\" fill-rule=\"evenodd\"><circle opacity=\".501\" cx=\"30\" cy=\"30\" r=\"30\"/><path d=\"M24 37.3c0 1.662 1.15 2.313 2.562 1.459l12.623-7.64c1.415-.857 1.41-2.247 0-3.101l-12.623-7.64C25.147 19.52 24 20.17 24 21.836V37.3z\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "width": "60",
      "height": "60",
      "viewBox": "0 0 60 60",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});