define("ember-svg-jar/inlined/android-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M0 0h16v16H0z\"/><path d=\"M11.333 13.333H4.667V2.667h6.666v2h1.334V2c0-.733-.6-1.333-1.334-1.333H4.667c-.734 0-1.334.6-1.334 1.333v12c0 .733.6 1.333 1.334 1.333h6.666c.734 0 1.334-.6 1.334-1.333v-2.667h-1.334v2z\" fill=\"#0097CF\" fill-rule=\"nonzero\"/><g stroke=\"#0097CF\" stroke-linecap=\"round\" stroke-width=\"1.333\"><path stroke-linejoin=\"round\" d=\"M11.521 10.035l-2.567.261.26-2.567\"/><path d=\"M8.954 10.296l4.663-4.663\"/></g></g>",
    "attrs": {
      "width": "17",
      "height": "16",
      "viewBox": "0 0 17 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});