define("ember-svg-jar/inlined/flag-nl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FF2A20\" d=\"M0 0h12v3H0z\"/><path fill=\"#FFF\" d=\"M0 3h12v3H0z\"/><path fill=\"#22608F\" d=\"M0 6h12v3H0z\"/></g>",
    "attrs": {
      "width": "12",
      "height": "9",
      "viewBox": "0 0 12 9",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});