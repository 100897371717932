define("ember-svg-jar/inlined/marketing-materials", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15.97.758A1 1 0 0116 1v9.439a1 1 0 01-1.243.97l-2.891-.724-.454 1.822a1 1 0 01-1.097.75l-.115-.022-4.852-1.21a1 1 0 01-.75-1.097l.022-.115.455-1.825L4 8.719v-6L14.757.03a1 1 0 011.213.728zM7.015 9.473l-.212.854 2.91.726.213-.853-2.911-.727zM3 2.719v6H2a1 1 0 01-1-1 1 1 0 01-1-1v-2a1 1 0 011-1 1 1 0 011-1h1z\" fill=\"currentColor\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "16",
      "height": "14",
      "viewBox": "0 0 16 14",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});