define("ember-svg-jar/inlined/faq", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><circle stroke=\"currentColor\" stroke-width=\"2\" cx=\"9\" cy=\"9\" r=\"8\"/><path d=\"M7 6.929a1.929 1.929 0 111.929 1.928v1.286\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M8.931 12a1 1 0 101 1 1.013 1.013 0 00-1-1z\" fill=\"currentColor\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});