define("ember-svg-jar/inlined/cart-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.985 4H17v2h-3.015v3h-2V6H9V4h2.985V1h2v3zm-6.118 9.6c.88 0 1.6.72 1.6 1.6 0 .88-.72 1.6-1.6 1.6-.88 0-1.592-.72-1.592-1.6 0-.88.712-1.6 1.592-1.6zm8 0c.88 0 1.6.72 1.6 1.6 0 .88-.72 1.6-1.6 1.6-.88 0-1.592-.72-1.592-1.6 0-.88.712-1.6 1.592-1.6zM5.337 11c0 .112.095.2.2.2h14.596v1.6H5.2c-.88 0-1.6-.72-1.6-1.6 0-.28.072-.544.2-.768l1.08-1.96L2 2.4H.4V.8h2.616l.752 1.6.76 1.6L6.32 7.784 6.424 8 6.08 9.6l-.72 1.304-.024.096z\" fill=\"currentColor\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "21",
      "height": "17",
      "viewBox": "0 0 21 17",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});