define("ember-svg-jar/inlined/arrow-right-large", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.912 4.16H.906C.406 4.16 0 4.537 0 5c0 .463.405.84.906.84h8.006L6.829 7.76a.8.8 0 000 1.192.96.96 0 001.286 0l3.622-3.357a.835.835 0 00.19-.277.736.736 0 000-.638.835.835 0 00-.19-.277L8.115 1.047a.96.96 0 00-1.286 0 .8.8 0 000 1.192L8.912 4.16z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "12",
      "height": "10",
      "viewBox": "0 0 12 10",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});