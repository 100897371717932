define("ember-svg-jar/inlined/plus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6 .5H4v4H0v2h4v4h2v-4h4v-2H6v-4z\" fill=\"#A8D6FB\"/>",
    "attrs": {
      "width": "10",
      "height": "11",
      "viewBox": "0 0 10 11",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});