define("ember-svg-jar/inlined/read-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M4.06 2.957a6.4 6.4 0 007.88 10.087l.063-.05.06-.049.061-.05.06-.051.06-.052.058-.052.058-.054.057-.053.057-.055.056-.055.055-.056.055-.056.053-.058.053-.057.053-.059.051-.059.051-.06.05-.06c.026-.03.026-.03.05-.061.025-.03.025-.03.049-.062l.048-.062.047-.062.046-.064.046-.063.044-.065c.023-.032.023-.032.044-.065l.044-.065.042-.067.041-.066.041-.067.04-.068.04-.068.037-.068.038-.07.036-.069.036-.07.035-.07.033-.071.033-.071.033-.072.031-.072.03-.072.03-.073.028-.073.028-.074.027-.074.026-.074.025-.074.024-.075.023-.075.022-.075.022-.076.02-.076.02-.076.018-.077.018-.076.016-.077.016-.077.015-.077a.8.8 0 111.573.291l-.018.097-.02.096-.02.096-.023.095-.023.096-.024.095-.026.095-.026.094-.028.094-.03.094-.03.094-.03.092-.033.093-.033.092-.035.092-.036.092-.037.09c-.018.046-.018.046-.038.091l-.039.09-.04.09-.041.09-.043.088-.043.088-.045.087c-.022.044-.022.044-.046.087l-.046.087-.048.085-.049.086-.05.084-.05.084-.053.084-.053.082-.054.083-.055.08-.056.082-.057.08-.058.079-.06.078c-.029.04-.029.04-.06.078l-.06.077-.062.077-.063.075-.063.075-.065.074-.066.073-.066.072-.067.072-.069.07-.069.07c-.034.035-.034.035-.07.069l-.07.068-.072.067-.072.067-.073.065-.074.065-.075.063-.075.063-.076.061-.077.061A8 8 0 1114.21 2.957l1.003-.556a.4.4 0 01.593.38l-.275 3.586a.4.4 0 01-.586.323l-3.163-1.68a.4.4 0 01-.006-.704l1.008-.558a6.4 6.4 0 00-8.724-.79zm3.89 9.033a4 4 0 110-8 4 4 0 010 8zm.8-5.6a.8.8 0 00-1.6 0v1.837a.8.8 0 00.325.644l1.049.775a.8.8 0 10.95-1.288l-.724-.535V6.39z\" fill=\"currentColor\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});