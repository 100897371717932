define("ember-svg-jar/inlined/favorites", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9 .5a1 1 0 01.951.691l1.572 4.837h5.085a1 1 0 01.588 1.809l-4.114 2.99 1.571 4.836a1 1 0 01-1.539 1.118L9 13.791l-4.114 2.99a1 1 0 01-1.54-1.118l1.572-4.837-4.114-2.99a1 1 0 01.588-1.808h5.085L8.05 1.19A1 1 0 019 .5zm0 4.236l-.845 2.6a1 1 0 01-.951.692H4.469l2.213 1.607a1 1 0 01.363 1.118L6.2 13.354l2.212-1.607a1 1 0 011.176 0l2.212 1.607-.845-2.6a1 1 0 01.363-1.119l2.213-1.607h-2.735a1 1 0 01-.95-.691L9 4.737z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});