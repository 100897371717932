define("ember-svg-jar/inlined/phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M6.768 9.23C5.186 7.647 3.672 5.813 4.397 5.088c1.037-1.037 1.94-1.677.102-3.965C2.66-1.164 1.435.593.43 1.598c-1.16 1.16-.062 5.484 4.21 9.758 4.275 4.273 8.599 5.368 9.759 4.207 1.006-1.005 2.762-2.225.475-4.063-2.287-1.839-2.927-.936-3.965.103-.725.722-2.56-.791-4.142-2.373z\" fill=\"currentColor\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});