define("ember-svg-jar/inlined/news", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0_1_3440)\"><path opacity=\".5\" d=\"M18 15.75a2.25 2.25 0 01-4.5 0V5.25H18v10.5z\" fill=\"currentColor\"/><path d=\"M8.25 3.75H12m-3.75 3H12m-9 3h9m-9 3h9\" stroke=\"#000\" stroke-width=\"1.5\" stroke-linejoin=\"round\"/><path opacity=\".5\" d=\"M5.75 4v2.5H4V4h1.75z\" stroke=\"#000\" stroke-width=\"2\"/><path clip-rule=\"evenodd\" d=\"M1 1h13v16H3a2 2 0 01-2-2V1z\" stroke=\"#000\" stroke-width=\"2\"/></g><defs><clipPath id=\"clip0_1_3440\"><path fill=\"#fff\" d=\"M0 0h18v18H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});