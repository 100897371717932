define("ember-svg-jar/inlined/exclamation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M21.049 16.029a2 2 0 01-1.748 2.97H2.7A2 2 0 01.95 16.03l8.3-14.941a2 2 0 013.498 0l8.3 14.94zM11 2.059l-8.3 14.94h16.6L11 2.06zm-1 5.44a1 1 0 112 0V11a1 1 0 11-2 0V7.5zm1 8a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5z\"/>",
    "attrs": {
      "width": "20",
      "height": "19",
      "viewBox": "0 0 22 19",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});