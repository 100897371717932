define("ember-svg-jar/inlined/chat-bubble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M6 8.25a.75.75 0 100 1.5.75.75 0 000-1.5zm3 0a.75.75 0 100 1.5.75.75 0 000-1.5zm3 0a.75.75 0 100 1.5.75.75 0 000-1.5zM9 1.5A7.5 7.5 0 001.5 9a7.417 7.417 0 001.695 4.748l-1.5 1.5a.75.75 0 00-.158.817.75.75 0 00.713.435H9a7.5 7.5 0 000-15zM9 15H4.057l.698-.697a.75.75 0 00.225-.533.75.75 0 00-.225-.525A6 6 0 119 15z\" fill=\"currentColor\"/><path d=\"M6 8.25a.75.75 0 100 1.5.75.75 0 000-1.5zm3 0a.75.75 0 100 1.5.75.75 0 000-1.5zm3 0a.75.75 0 100 1.5.75.75 0 000-1.5zM9 15H4.058l.697-.697a.75.75 0 00.225-.533.75.75 0 00-.225-.525A6 6 0 119 15z\" fill=\"currentColor\"/><g fill=\"#fff\"><circle cx=\"6\" cy=\"9\" r=\"1\"/><circle cx=\"9\" cy=\"9\" r=\"1\"/><circle cx=\"12\" cy=\"9\" r=\"1\"/></g>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});