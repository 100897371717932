define("ember-svg-jar/inlined/arrow-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" fill-rule=\"nonzero\" d=\"M5.558 7.942L2.123 4.5l3.435-3.443L4.5 0 0 4.5 4.5 9z\"/>",
    "attrs": {
      "width": "6",
      "height": "9",
      "viewBox": "0 0 6 9",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});