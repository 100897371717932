define("ember-svg-jar/inlined/registration-approve", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><rect width=\"62\" height=\"83\" rx=\"1.5\" fill=\"#FFF\"/><rect stroke=\"#1D3C57\" stroke-width=\"1.688\" x=\".844\" y=\".844\" width=\"60.313\" height=\"81.313\" rx=\"1.5\"/><text fill=\"#2B4257\" fill-rule=\"nonzero\" font-family=\"ProximaNova-Semibold, Proxima Nova\" font-size=\"13.5\" font-weight=\"500\"><tspan x=\"9.711\" y=\"20.791\">&#x20ac;</tspan></text><path fill=\"#C4CDD6\" fill-rule=\"nonzero\" d=\"M26.145 9.055H52.29v3.773H26.145zM26.145 16.6H52.29v3.773H26.145zM10.458 43.01h41.831v-3.774H10.458zM10.458 58.854h41.831v-3.773H10.458zM10.458 35.464h41.831V31.69H10.458zM10.458 51.31h41.831v-3.774H10.458z\"/><g fill=\"#50ADF7\" fill-rule=\"nonzero\"><path d=\"M16.982 64.25c-2.656 1.365-3.027 4.345-3.118 7.008l1.594-.652-2.62-2.168c-.916-.758-2.244.54-1.32 1.304l2.62 2.168c.618.512 1.565.195 1.594-.652.068-1.998.165-4.374 2.192-5.416 1.07-.549.124-2.14-.942-1.593M18.033 69.31c.48.96 1.401 1.659 2.493 1.782.567.064 1.043-.012 1.576-.208.38-.14 1.795-1.229 2.117-.661.587 1.038 2.2.108 1.612-.93-.485-.859-1.533-1.284-2.51-1.01-1.274.355-2.783 1.882-3.675.097-.533-1.067-2.143-.132-1.613.93\"/></g></g>",
    "attrs": {
      "width": "62",
      "height": "83",
      "viewBox": "0 0 62 83",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});