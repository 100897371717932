define("ember-svg-jar/inlined/customer-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14 0H2C.675 0 .008.675 0 2v13l3-3h11c.325 0 1-.675 1-1V2c0-1.325-.675-2-1-2zM3 5h9v1H3V5zm6 3H3V7h6v1zm3-4H3V3h9v1z\" fill=\"currentColor\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "15",
      "height": "15",
      "viewBox": "0 0 15 15",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});