define("ember-service-worker-update-notify/components/service-worker-update-notify", ["exports", "@ember/component", "ember-service-worker-update-notify/templates/components/service-worker-update-notify", "@ember/service"], function (_exports, _component, _serviceWorkerUpdateNotify, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    layout: _serviceWorkerUpdateNotify.default,
    tagName: '',
    serviceWorkerUpdateNotify: (0, _service.inject)(),
    hasUpdate: false,
    init() {
      this._super(...arguments);
      this._updateHandler = () => this.set('hasUpdate', true);
      this.serviceWorkerUpdateNotify.on('update', this._updateHandler);
    },
    willDestroy() {
      this._super(...arguments);
      this.serviceWorkerUpdateNotify.off('update', this._updateHandler);
    }
  });
  _exports.default = _default;
});