define("ember-svg-jar/inlined/invoices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<mask id=\"a\" style=\"mask-type:alpha\" maskUnits=\"userSpaceOnUse\" x=\"1\" y=\"1\" width=\"16\" height=\"16\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.5 1h-9v16h15V7l-6-6z\" fill=\"currentColor\"/></mask><g mask=\"url(#a)\" fill=\"currentColor\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.5 15h11V3h-11v12zm-2-14v16h15V1h-15z\"/><path d=\"M10.5 1l6 6h-6V1z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.5 9h-3V7h3v2zM12.5 13h-7v-2h7v2z\"/></g>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});