define("ember-svg-jar/inlined/logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M173.244 21.67c-1.603 1.514-3.607 2.33-6.182 2.33-5.897 0-9.675-4.252-9.675-10.66 0-3.787 1.088-6.99 3.091-9.32C162.825 1.222 165.745 0 169.58 0c6.183 0 9.847 2.97 10.42 8.388h-6.641c-.344-1.805-1.718-2.854-3.663-2.854-3.15 0-5.267 3.146-5.267 8.039 0 3.145 1.373 4.777 3.95 4.777 1.66 0 3.09-.874 4.006-2.564h-3.09l.743-4.66h9.733l-1.833 12.233h-4.522l-.172-1.69zM132.06 23.11h6.682l1.67-11.451a9.83 9.83 0 00.173-1.87v-.624c.115 1.247.288 2.04.46 2.38l5.3 11.565h6.681L156.482.89h-6.739l-1.728 11.338c-.057.623-.172 1.53-.172 2.664 0-.964-.173-1.814-.576-2.664L142.025.889h-6.566L132.06 23.11zm-10.854 0h7.353L132.06.89h-7.353l-3.501 22.222zm-23.518 0h7.107l1.234-7.993h1.88c2.642 0 3.758.567 3.758 2.55 0 .284 0 .625-.058 1.021l-.294 2.211c-.059.567-.117.964-.117 1.19 0 .398.058.738.117 1.021h7.753v-.567c-.47-.17-.705-.51-.705-1.077 0-.283.059-.51.059-.794l.294-2.21c.058-.567.058-1.02.058-1.418 0-1.984-.763-3.118-2.819-3.855 2.878-1.19 4.347-3.344 4.347-6.292 0-4.195-2.702-6.01-8.517-6.01h-10.69l-3.407 22.223zm12.323-12.444c2.055 0 3.054-.721 3.054-2.463 0-1.441-.833-1.982-2.777-1.982h-2.943l-.611 4.445h3.277zM77.789 23.11h18.11l.803-5.385H85.698l.458-3.515h9.17l.803-4.989H86.9l.516-3.004h10.374l.802-5.33H81.228l-3.44 22.223zm-20.804 0h18.103l.804-5.385H64.858l.46-3.515h9.195l.805-4.989h-9.195l.46-3.004h10.401l.805-5.33H60.433l-3.448 22.223zm-25.327 0h7.16l1.27-8.673h6.64l-1.27 8.673h7.274L56.08.89h-7.217l-1.212 7.653h-6.64l1.27-7.653h-7.216L31.658 23.11zm-15.377 0h3.983L23.518.89h-3.983L16.28 23.11zm5.428 0h5.593L30.754.89H25.22L21.709 23.11zm-9.95 0h3.166L18.09.89h-3.165L11.759 23.11zm-4.523 0h2.67L13.567.89h-2.67L7.237 23.11zm-3.618 0h1.437L8.14.89H6.757L3.618 23.11zm-3.618 0h.88L4.522.89h-.88L0 23.11z\" fill=\"#FBFBFB\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "180",
      "height": "24",
      "viewBox": "0 0 180 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});