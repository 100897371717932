define("ember-svg-jar/inlined/mail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M.574 1.286l7.5 4.029c.252.135.578.199.906.199.328 0 .654-.064.906-.199l7.5-4.029c.489-.263.951-1.286.054-1.286H.521c-.897 0-.435 1.023.053 1.286zm17.039 2.203L9.886 7.516c-.34.178-.578.199-.906.199-.328 0-.566-.021-.906-.199C7.734 7.338.941 3.777.386 3.488c-.39-.204-.386.035-.386.219V11c0 .42.566 1 1 1h16c.434 0 1-.58 1-1V3.708c0-.184.004-.423-.387-.219z\" fill=\"currentColor\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "18",
      "height": "12",
      "viewBox": "0 0 18 12",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});