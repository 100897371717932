define("ember-svg-jar/inlined/cross", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\" stroke=\"#000\" stroke-width=\"2\"><path d=\"M1.8 1.8l6.329 6.215M8.2 1.8L1.8 8.257\"/></g>",
    "attrs": {
      "width": "10",
      "height": "10",
      "viewBox": "0 0 10 10",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});