define("ember-svg-jar/inlined/trash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M1.263 13.965c0 1.185.79 1.93 2.105 1.93h6.316c1.316 0 2.105-.745 2.105-1.93v-9.65H1.263v9.65zm2.105-6.491L4.421 6.42l2.105 2.105 2.106-2.105 1.052 1.053L7.58 9.579l2.105 2.105-1.052 1.053-2.106-2.105-2.105 2.105-1.053-1.053L5.474 9.58 3.368 7.474zm6.316-6.316L8.632.105h-4.21L3.367 1.158H.211v2.105h12.631V1.158H9.684z\" fill=\"currentColor\" fill-rule=\"nonzero\" opacity=\".75\"/>",
    "attrs": {
      "width": "13",
      "height": "16",
      "viewBox": "0 0 13 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});