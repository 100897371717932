define("ember-svg-jar/inlined/registration-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><rect id=\"a\" x=\"0\" y=\".374\" width=\"61.731\" height=\"82.252\" rx=\"2.813\"/><rect id=\"b\" x=\"0\" y=\".374\" width=\"53.874\" height=\"60.568\" rx=\"1.688\"/><ellipse id=\"d\" cx=\"16.836\" cy=\"16.824\" rx=\"16.836\" ry=\"16.824\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><use fill=\"#FFF\" xlink:href=\"#a\"/><rect stroke=\"#005F85\" stroke-width=\"1.688\" x=\".844\" y=\"1.218\" width=\"60.043\" height=\"80.565\" rx=\"2.813\"/><circle fill=\"#AACBD8\" cx=\"31.122\" cy=\"5.608\" r=\"1.122\"/><g transform=\"translate(3.928 8.786)\"><mask id=\"c\" fill=\"#fff\"><use xlink:href=\"#b\"/></mask><use fill=\"#AACBD8\" xlink:href=\"#b\"/><g mask=\"url(#c)\" fill-opacity=\".6\"><path fill=\"#CCDFE7\" d=\"M5.612 5.795H47.7v10.282H5.61zM5.612 19.441H47.7v5.608H5.61z\"/><g fill=\"#CCDFE7\"><path d=\"M5.612 28.414h15.152v15.142H5.612zM24.13 28.414H47.7v2.804H24.13zM24.13 34.022H47.7v1.682H24.13zM24.13 36.826H47.7v1.682H24.13z\"/></g><g fill=\"#CCDFE7\"><path d=\"M5.612 46.36h15.152v15.142H5.612zM24.13 46.36H47.7v2.804H24.13zM24.13 51.968H47.7v1.682H24.13zM24.13 54.772H47.7v1.682H24.13z\"/></g><g fill=\"#CCDFE7\"><path d=\"M5.612 64.306h15.152v15.142H5.612zM24.13 64.306h52.192v2.804H24.13zM24.13 69.914h49.947v1.682H24.13zM24.13 72.718h44.335V74.4H24.13z\"/></g></g></g><ellipse fill=\"#AACBD8\" cx=\"30.865\" cy=\"75.336\" rx=\"2.806\" ry=\"2.804\"/><g><g transform=\"translate(43.773 20.19)\"><use fill=\"#76BC2A\" xlink:href=\"#d\"/><ellipse stroke=\"#005F85\" stroke-width=\"1.688\" cx=\"16.836\" cy=\"16.824\" rx=\"15.992\" ry=\"15.981\"/></g><path d=\"M58.816 42.78c-.314 0-.61-.146-.801-.398l-2.578-3.404a1.005 1.005 0 111.604-1.212l1.696 2.238 4.265-6.844c.294-.47.914-.615 1.386-.321.471.292.616.913.322 1.384l-5.04 8.083a1.003 1.003 0 01-.854.474z\" fill-rule=\"nonzero\" fill=\"#FFF\"/></g></g>",
    "attrs": {
      "width": "78",
      "height": "83",
      "viewBox": "0 0 78 83",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});