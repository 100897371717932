define("ember-svg-jar/inlined/logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0_2_3901)\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"currentColor\"><path d=\"M0 3a3 3 0 013-3h6v2H3a1 1 0 00-1 1v10a1 1 0 001 1h6v2H3a3 3 0 01-3-3V3z\"/><path d=\"M14.5 9H5V7h9.5v2z\"/><path d=\"M11.707 3.793L15.914 8l-4.207 4.207-1.414-1.414L13.086 8l-2.793-2.793 1.414-1.414z\"/></g><defs><clipPath id=\"clip0_2_3901\"><path fill=\"#fff\" d=\"M0 0h16v16H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});