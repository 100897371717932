define("ember-svg-jar/inlined/product-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"none\" d=\"M-1-1h15v15H-1z\"/><path fill=\"currentColor\" fill-rule=\"nonzero\" d=\"M0 0h3v3H0zM0 5h3v3H0zM5 0h3v3H5zM5 5h3v3H5zM10 0h3v3h-3zM10 5h3v3h-3zM0 10h3v3H0zM5 10h3v3H5z\"/></g>",
    "attrs": {
      "width": "13",
      "height": "13",
      "viewBox": "0 0 13 13",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});