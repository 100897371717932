define("ember-svg-jar/inlined/paperclip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M4.206 5.271a2.794 2.794 0 013.95 0l.519.52a.583.583 0 11-.829.764l-.519-.525a1.633 1.633 0 00-2.298 0L1.646 9.419a1.598 1.598 0 00-.479 1.167c-.005.437.168.858.479 1.166l.519.52a1.668 1.668 0 002.298 0l1.598-1.599a.583.583 0 01.823.03.583.583 0 010 .828L5.29 13.123a2.788 2.788 0 01-3.955 0l-.519-.519a2.794 2.794 0 010-3.95zM8.628.815a2.8 2.8 0 013.95 0l.524.519h.03a2.794 2.794 0 010 3.95l-3.39 3.388a2.771 2.771 0 01-1.971.817 2.777 2.777 0 01-1.978-.823.583.583 0 01.788-.781c.307.312.728.485 1.166.478.438.005.859-.167 1.167-.478L12.297 4.5c.311-.308.484-.729.479-1.166a1.598 1.598 0 00-.479-1.167l-.519-.525a1.628 1.628 0 00-2.298 0L7.782 3.335a.583.583 0 11-.822-.823z\" fill=\"currentColor\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});