define("ember-svg-jar/inlined/color-samples", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"currentColor\" fill-rule=\"nonzero\"><path d=\"M10.251 1.473l3.277 2.295a2 2 0 01.49 2.785L8 15.149V1.443a2 2 0 012.251.03z\" opacity=\".5\"/><path d=\"M6 0a2 2 0 011.934 1.49c.67-.485 1.6-.518 2.317-.017l3.277 2.295a2 2 0 01.495 2.78 2 2 0 012.111 1.302l1.368 3.759a2 2 0 01-1.195 2.563L5.97 17.934c-.934.34-1.914.31-2.777-.015A4.003 4.003 0 010 14V2a2 2 0 012-2h4zm0 2H2v12a2 2 0 003.995.15L6 14V2zm8.255 6.534l-2.179.793-4 5.712 7.547-2.746-1.368-3.759zM9.104 3.112L8 4.686v6.975l4.38-6.255-3.276-2.294z\"/></g>",
    "attrs": {
      "width": "18",
      "height": "19",
      "viewBox": "0 0 18 19",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});