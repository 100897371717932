define("ember-svg-jar/inlined/saved-lists", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0_2_3915)\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M8.5 12H1v-2h7.5v2zm0 4H1v-2h7.5v2z\" fill=\"currentColor\"/><path d=\"M19.243 10L10.5 18.743 3.757 12 8 7.757l2.5 2.5 4.5-4.5L19.243 10z\" fill=\"#fff\"/><path d=\"M16.914 9.5L10.5 15.914 6.586 12 8 10.586l2.5 2.5 5-5L16.914 9.5zM13 4H1V2h12v2zm0 4H1V6h12v2z\" fill=\"currentColor\"/></g><defs><clipPath id=\"clip0_2_3915\"><path fill=\"#fff\" d=\"M0 0h18v18H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});