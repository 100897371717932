define("ember-svg-jar/inlined/checkbox-checked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<rect width=\"20\" height=\"20\" rx=\"3\" fill=\"#479BDD\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15.511 5.175a.833.833 0 01.147 1.17l-6.48 8.333a.834.834 0 01-1.23.094l-3.521-3.334a.833.833 0 111.146-1.21l2.854 2.702 5.915-7.608a.833.833 0 011.17-.147z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});