define("ember-svg-jar/inlined/whatsapp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.181 0C4.76 0 .363 4.362.363 9.742c0 1.841.515 3.563 1.409 5.031L0 20l5.436-1.727a9.838 9.838 0 004.745 1.212c5.423 0 9.819-4.362 9.819-9.742C20 4.361 15.604 0 10.181 0zm4.883 13.442c-.231.573-1.276 1.096-1.737 1.12-.46.024-.474.357-2.984-.734-2.51-1.09-4.02-3.743-4.14-3.914-.118-.17-.971-1.386-.925-2.61.046-1.223.716-1.8.955-2.04a.962.962 0 01.68-.287c.198-.003.326-.006.472 0 .146.005.366-.031.556.474.19.506.645 1.748.703 1.875.058.127.094.273.004.436-.09.163-.135.265-.266.406-.13.14-.275.314-.392.422-.13.118-.267.248-.13.503.137.254.61 1.087 1.33 1.774.925.882 1.723 1.175 1.968 1.308.247.134.394.12.546-.041.152-.16.654-.703.83-.945.177-.243.342-.195.57-.104.226.091 1.435.739 1.681.873.246.133.41.202.47.308.058.106.04.604-.191 1.176z\" fill=\"currentColor\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});