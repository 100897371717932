define("ember-svg-jar/inlined/products", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<rect x=\"1\" y=\"2\" width=\"2\" height=\"2\" rx=\"1\" fill=\"currentColor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15 4H5V2h10v2z\" fill=\"currentColor\"/><rect x=\"1\" y=\"6\" width=\"2\" height=\"2\" rx=\"1\" fill=\"currentColor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15 8H5V6h10v2z\" fill=\"currentColor\"/><rect x=\"1\" y=\"10\" width=\"2\" height=\"2\" rx=\"1\" fill=\"currentColor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9 12H5v-2h4v2z\" fill=\"currentColor\"/><rect x=\"1\" y=\"14\" width=\"2\" height=\"2\" rx=\"1\" fill=\"currentColor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9 16H5v-2h4v2z\" fill=\"currentColor\"/><path fill=\"currentColor\" d=\"M11 12h6v2h-6z\"/><path fill=\"currentColor\" d=\"M15 10v6h-2v-6z\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});