define("ember-svg-jar/inlined/container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0_2_3985)\"><mask id=\"a\" style=\"mask-type:alpha\" maskUnits=\"userSpaceOnUse\" x=\"0\" y=\"0\" width=\"18\" height=\"18\"><path fill=\"currentColor\" d=\"M0 0h18v18H0z\"/></mask><g mask=\"url(#a)\"><mask id=\"b\" style=\"mask-type:alpha\" maskUnits=\"userSpaceOnUse\" x=\"0\" y=\"0\" width=\"18\" height=\"18\"><path d=\"M18 0H0v18h18V0z\" fill=\"#fff\"/></mask><g mask=\"url(#b)\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"currentColor\"><path d=\"M19.15 6c.11 0 .2.09.2.2v8.6a.2.2 0 01-.2.2h-6.087a.2.2 0 01-.2-.2V6.2c0-.11.09-.2.2-.2h6.086zm2.2.2a2.2 2.2 0 00-2.2-2.2h-6.087a2.2 2.2 0 00-2.2 2.2v8.6a2.2 2.2 0 002.2 2.2h6.086a2.2 2.2 0 002.2-2.2V6.2z\"/><path d=\"M29.8 6c.11 0 .2.09.2.2v8.6a.2.2 0 01-.2.2H7.038a.2.2 0 01-.17-.307l5.484-8.6a.2.2 0 01.17-.093H29.8zm2.2.2A2.2 2.2 0 0029.8 4H12.521a2.2 2.2 0 00-1.855 1.017l-5.483 8.6A2.2 2.2 0 007.038 17H29.8a2.2 2.2 0 002.2-2.2V6.2z\"/><path d=\"M11.633 15.03l7.555-10.207 1.607 1.19-7.554 10.207-1.608-1.19z\"/><path d=\"M4.37 10.757a1 1 0 01.305 1.38l-1.643 2.577a.2.2 0 00.06.277l.004.002c.032.02.069.031.104.031h22.763a1 1 0 110 2H3.2c-.42 0-.831-.122-1.183-.347l.54-.842-.537.844a2.2 2.2 0 01-.676-3.037l1.645-2.58a1 1 0 011.38-.305zM5.874 1.31a1 1 0 01-.035 1.414L3.45 5l2.39 2.276a1 1 0 01-1.38 1.448l-3.15-3a1 1 0 010-1.447l3.15-3.001a1 1 0 011.414.034z\"/><path d=\"M1 5a1 1 0 011-1h6a1 1 0 110 2H2a1 1 0 01-1-1z\"/></g></g></g><defs><clipPath id=\"clip0_2_3985\"><path fill=\"#fff\" d=\"M0 0h18v18H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});