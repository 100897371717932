define("ember-slugify/index", ["exports", "slugify", "simple-pinyin", "emoji.json"], function (_exports, slugfyFunction, _simplePinyin, _emoji) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.slugify = _exports.default = slugify;
  _exports.removeDiacritics = removeDiacritics;
  const UNWANTED_OPTIONS = ['strict' // strip special characters except replacement (boolean)
  ];

  function removeDiacritics() {
    let str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return str.normalize('NFKD').replace(/[\u0300-\u036F]/g, '');
  }
  function _parseLocale(locale) {
    if (typeof locale !== 'string') {
      return;
    }
    if (locale.includes('-')) {
      return locale.split('-')[0];
    }
    if (locale.includes('_')) {
      return locale.split('_')[0];
    }
    return locale;
  }
  function slugify() {
    let str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    UNWANTED_OPTIONS.forEach(key => {
      if (options[key] !== undefined) {
        delete options[key];
      }
    });
    let locale = _parseLocale(options.locale);
    let result = removeDiacritics(str);
    if (options.pinyin) {
      result = (0, _simplePinyin.default)(result, {
        matchFullText: 'original'
      }).reduce((acc, b) => b.length === 1 ? `${acc}${b}` : `${acc} ${b}`, '');
    }
    if (options.emoji) {
      _emoji.default.forEach(emoji => {
        result = result.replaceAll(emoji.char, ` ${emoji.name} `);
      });
    }
    return slugfyFunction(result, Object.assign({
      replacement: '-',
      lower: true,
      strict: true,
      locale: undefined,
      trim: true,
      pinyin: false,
      emoji: false
    }, options, {
      locale
    }));
  }
});