define("ember-svg-jar/inlined/download-pdf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><g stroke=\"#50ADF7\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M8 1v4a1 1 0 001 1h4v9a2 2 0 01-2 2H3a2 2 0 01-2-2V3a2 2 0 012-2h5z\"/><path d=\"M13 6v9a2 2 0 01-2 2H3a2 2 0 01-2-2V3a2 2 0 012-2h5l5 5z\"/><path d=\"M9 11.284l-2 2-2-2M7 12.5v-4\"/></g></g>",
    "attrs": {
      "width": "14",
      "height": "18",
      "viewBox": "0 0 14 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});