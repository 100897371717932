define("ember-svg-jar/inlined/company-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.5 16.25a.75.75 0 01.75-.75h3a.75.75 0 010 1.5h-3a.75.75 0 01-.75-.75zM18.5 16.25a.75.75 0 01.75-.75h3a.75.75 0 010 1.5h-3a.75.75 0 01-.75-.75zM18.5 13.25a.75.75 0 01.75-.75h3a.75.75 0 010 1.5h-3a.75.75 0 01-.75-.75zM18.5 10.25a.75.75 0 01.75-.75h3a.75.75 0 010 1.5h-3a.75.75 0 01-.75-.75zM8.5 19a.75.75 0 01.75-.75h3a.75.75 0 010 1.5h-3A.75.75 0 018.5 19zM8.5 21.75a.75.75 0 01.75-.75h3a.75.75 0 010 1.5h-3a.75.75 0 01-.75-.75z\" fill=\"#C4CDD6\"/><path d=\"M13.5 13H16v13h-2.5V13z\" fill=\"#E1E6EA\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7 13.5v12h7.5v-12H7zM6.5 12a1 1 0 00-1 1v13a1 1 0 001 1H15a1 1 0 001-1V13a1 1 0 00-1-1H6.5z\" fill=\"#C4CDD6\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16 6.5v19h9v-19h-9zM15.5 5a1 1 0 00-1 1v20a1 1 0 001 1h10a1 1 0 001-1V6a1 1 0 00-1-1h-10z\" fill=\"#C4CDD6\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M19.5 20.5v5h2v-5h-2zM19 19a1 1 0 00-1 1v6a1 1 0 001 1h3a1 1 0 001-1v-6a1 1 0 00-1-1h-3z\" fill=\"#FFCB55\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.5 26.25a.75.75 0 01.75-.75h23.5a.75.75 0 010 1.5H4.25a.75.75 0 01-.75-.75z\" fill=\"#C4CDD6\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});