define("ember-svg-jar/inlined/apple-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M3.405 5.448v1.286h-1.12a.643.643 0 00-.635.548l-.007.095v6.337c0 .323.238.59.548.636l.095.007h8.428c.323 0 .59-.238.636-.548l.007-.095V7.377a.643.643 0 00-.548-.636l-.095-.007H9.691V5.448h1.023c1.015 0 1.846.783 1.923 1.778l.006.15v6.338a1.929 1.929 0 01-1.778 1.923l-.15.006h-8.43a1.929 1.929 0 01-1.922-1.778l-.006-.15V7.376c0-1.015.783-1.846 1.778-1.923l.15-.006h1.12zM6.923.516l.075.078 1.929 2.364-.997.813-.788-.967v8.089H5.858V2.805l-.787.966-.997-.813L6.002.594a.643.643 0 01.92-.078z\" fill=\"#50ADF7\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "width": "13",
      "height": "16",
      "viewBox": "0 0 13 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});