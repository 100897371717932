define("ember-svg-jar/inlined/info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"nonzero\"><circle fill=\"#50ADF7\" cx=\"7.5\" cy=\"7.5\" r=\"7.5\"/><path d=\"M6.74 6c.18 0 .36.018.539.055.495.101.88.384 1.08.797.245.504.195 1.148-.138 1.814l-.563 1.121c-.3.599-.125.722-.03.788.02.013.08.044.228.044.27 0 .581-.103.585-.104a.282.282 0 01.276.478c-.03.025-.73.633-1.77.633-.18 0-.36-.02-.537-.055-.497-.101-.88-.384-1.081-.796-.245-.504-.196-1.148.138-1.815l.562-1.122c.3-.597.124-.722.029-.788-.019-.013-.078-.044-.226-.044-.27 0-.582.104-.585.105a.28.28 0 01-.277-.477A2.86 2.86 0 016.74 6zm1.229-3.374a1.406 1.406 0 110 2.812 1.406 1.406 0 010-2.812z\" fill=\"#FFF\"/></g>",
    "attrs": {
      "width": "15",
      "height": "15",
      "viewBox": "0 0 15 15",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});