define("ember-svg-jar/inlined/unfavorite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M2.076 7.978C1.243 7.38.894 6.868 1.028 6.443c.134-.425.716-.637 1.744-.637h3.875l1.182-3.564v-.017c.212-.637.461-1.069.746-1.295.285-.227.57-.227.851 0 .283.226.53.658.743 1.295l.008.017 1.174 3.564h3.874c.671 0 1.158.098 1.46.294.301.195.387.46.255.792-.131.333-.47.695-1.019 1.086l-.084.067-3.035 2.172 1.165 3.556.026.033c.313.973.288 1.595-.076 1.866-.363.271-.961.108-1.794-.49l-.05-.034-.043-.025-3.035-2.18-3.078 2.205-.034.034c-.833.598-1.434.761-1.803.49s-.39-.893-.067-1.866l.009-.017v-.025l1.165-3.547-3.035-2.172-.034-.033-.042-.034z\" stroke=\"currentColor\" stroke-width=\"1.5\" fill=\"none\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "18",
      "height": "17",
      "viewBox": "0 0 18 17",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});