define("ember-svg-jar/inlined/registration-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><rect width=\"62\" height=\"83\" rx=\"1.5\" fill=\"#FFF\"/><rect stroke=\"#1D3C57\" stroke-width=\"1.688\" x=\".844\" y=\".844\" width=\"60.313\" height=\"81.313\" rx=\"1.5\"/><g transform=\"translate(7.47 12.073)\" fill-rule=\"nonzero\"><rect fill=\"#AACBD8\" y=\"31.691\" width=\"26.145\" height=\"11.318\" rx=\"2.813\"/><rect fill=\"#AACBD8\" x=\"32.12\" y=\"31.691\" width=\"14.193\" height=\"11.318\" rx=\"1.5\"/><rect fill=\"#50ADF7\" y=\"47.536\" width=\"46.313\" height=\"11.318\" rx=\"1.5\"/><rect fill=\"#AACBD8\" y=\"15.845\" width=\"46.313\" height=\"11.318\" rx=\"1.5\"/><rect fill=\"#AACBD8\" width=\"46.313\" height=\"11.318\" rx=\"1.5\"/><rect fill=\"#FFF\" opacity=\".552\" x=\"14.94\" y=\"51.309\" width=\"16.434\" height=\"3.018\" rx=\"1.5\"/><path fill=\"#1D3C57\" d=\"M3.735 3.018h1.494V8.3H3.735z\"/></g></g>",
    "attrs": {
      "width": "62",
      "height": "83",
      "viewBox": "0 0 62 83",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});