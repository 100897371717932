define("ember-svg-jar/inlined/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M1 13.167C1 14.293 1.75 15 3 15h6c1.25 0 2-.707 2-1.833V4H1v9.167zM3 7l1-1 2 2 2-2 1 1-2 2 2 2-1 1-2-2-2 2-1-1 2-2-2-2zm6-6L8 0H4L3 1H0v2h12V1H9z\" fill=\"currentColor\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "12",
      "height": "15",
      "viewBox": "0 0 12 15",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});