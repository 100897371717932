define("ember-svg-jar/inlined/check-thin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12.5.429l1.64 1.142-7.577 10.89L.365 7.372l1.27-1.546L6.158 9.54z\" fill=\"currentColor\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "15",
      "height": "13",
      "viewBox": "0 0 15 13",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});