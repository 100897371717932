define("ember-svg-jar/inlined/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M6.5 5.571a2.774 2.774 0 002.776-2.785A2.774 2.774 0 006.5 0a2.782 2.782 0 00-2.786 2.786A2.782 2.782 0 006.5 5.57zm0 1.858c-2.164 0-6.5 1.086-6.5 3.25V13h13v-2.321c0-2.164-4.336-3.25-6.5-3.25z\" fill=\"currentColor\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "13",
      "height": "13",
      "viewBox": "0 0 13 13",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});