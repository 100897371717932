define("ember-file-drop-zone/components/file-drop-zone", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div {{did-insert this.registerListener}}
       {{will-destroy this.unregisterListener}}
       class="ember-file-drop-zone {{if this.disabled 'disabled'}} {{if this.dragging 'dragging'}} {{if this.hovering 'hovering'}}">
    {{yield (hash dragging=this.dragging hovering=this.hovering)}}
  </div>
  */
  {
    "id": "BepFb3x1",
    "block": "[[[11,0],[16,0,[29,[\"ember-file-drop-zone \",[52,[30,0,[\"disabled\"]],\"disabled\"],\" \",[52,[30,0,[\"dragging\"]],\"dragging\"],\" \",[52,[30,0,[\"hovering\"]],\"hovering\"]]]],[4,[38,1],[[30,0,[\"registerListener\"]]],null],[4,[38,2],[[30,0,[\"unregisterListener\"]]],null],[12],[1,\"\\n  \"],[18,1,[[28,[37,4],null,[[\"dragging\",\"hovering\"],[[30,0,[\"dragging\"]],[30,0,[\"hovering\"]]]]]]],[1,\"\\n\"],[13]],[\"&default\"],false,[\"if\",\"did-insert\",\"will-destroy\",\"yield\",\"hash\"]]",
    "moduleName": "ember-file-drop-zone/components/file-drop-zone.hbs",
    "isStrictMode": false
  });
  let FileDropZoneComponent = (_class = class FileDropZoneComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "hovering", _descriptor, this);
      _initializerDefineProperty(this, "dragging", _descriptor2, this);
      _initializerDefineProperty(this, "windowEnteredCounter", _descriptor3, this);
    }
    registerListener(element) {
      window.addEventListener('dragenter', this.onWindowDragEnter, false);
      window.addEventListener('dragleave', this.onWindowDragLeave), false;
      window.addEventListener('dragover', this.onWindowDragOver, false);
      window.addEventListener('drop', this.onWindowDrop, false);
      element.addEventListener('dragenter', this.onDragEnter, false);
      element.addEventListener('dragleave', this.onDragLeave), false;
      element.addEventListener('dragover', this.onDragOver, false);
      element.addEventListener('drop', this.onDrop, false);
    }
    unregisterListener(element) {
      window.removeEventListener('dragenter', this.onWindowDragEnter, false);
      window.removeEventListener('dragleave', this.onWindowDragLeave, false);
      window.removeEventListener('dragover', this.onWindowDragOver, false);
      window.removeEventListener('drop', this.onWindowDrop, false);
      element.removeEventListener('dragenter', this.onDragEnter, false);
      element.removeEventListener('dragleave', this.onDragLeave), false;
      element.removeEventListener('dragover', this.onDragOver, false);
      element.removeEventListener('drop', this.onDrop, false);
    }
    onWindowDragEnter() {
      this.dragging = true;
      ++this.windowEnteredCounter;
    }
    onWindowDragLeave() {
      if (--this.windowEnteredCounter == 0) {
        this.dragging = false;
      }
    }
    onWindowDragOver(e) {
      e.preventDefault();
    }
    onWindowDrop(e) {
      e.preventDefault();
      this.reset();
    }
    onDragEnter(e) {
      this.hovering = true;
      if (!this.args.disabled && this.args.onDragEnter) {
        this.args.onDragEnter(this.extractFiles(e).length);
      }
    }
    onDragLeave() {
      this.hovering = false;
      if (!this.args.disabled && this.args.onDragLeave) {
        this.args.onDragLeave();
      }
    }
    onDragOver(e) {
      e.preventDefault();
    }
    onDrop(e) {
      e.preventDefault();
      this.reset();
      if (this.args.disabled) {
        return true;
      }
      if (this.args.onDrop) {
        this.args.onDrop(this.extractFiles(e));
      }
    }
    reset() {
      this.dragging = false;
      this.hovering = false;
      this.windowEnteredCounter = 0;
    }
    extractFiles(event) {
      let files = [];
      if (event.dataTransfer?.items) {
        // Use DataTransferItemList interface to access the file(s)
        for (var i = 0; i < event.dataTransfer.items.length; i++) {
          // If dropped items aren't files, reject them
          const item = event.dataTransfer.items[i];
          if (item.kind === 'file') {
            files.push(item.getAsFile());
          }
        }
      } else {
        // Use DataTransfer interface to access the file(s)
        for (var j = 0; j < event.dataTransfer.files.length; j++) {
          files.push(event.dataTransfer.files[j]);
        }
      }
      return files;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "hovering", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dragging", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "windowEnteredCounter", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "registerListener", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registerListener"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unregisterListener", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "unregisterListener"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onWindowDragEnter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onWindowDragEnter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onWindowDragLeave", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onWindowDragLeave"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onWindowDragOver", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onWindowDragOver"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onWindowDrop", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onWindowDrop"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDragEnter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onDragEnter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDragLeave", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onDragLeave"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDragOver", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onDragOver"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDrop", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onDrop"), _class.prototype)), _class);
  _exports.default = FileDropZoneComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FileDropZoneComponent);
});