define("ember-svg-jar/inlined/chevron-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" fill-rule=\"nonzero\" d=\"M2 8.942L5.435 5.5 2 2.057 3.057 1l4.5 4.5-4.5 4.5z\"/>",
    "attrs": {
      "width": "10",
      "height": "10",
      "viewBox": "0 0 10 10",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});