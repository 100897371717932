define("ember-svg-jar/inlined/cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M7.72 17.71c-.912 0-1.649.76-1.649 1.69 0 .928.737 1.688 1.648 1.688.911 0 1.657-.76 1.657-1.689s-.746-1.688-1.657-1.688zm9.108 0c.911 0 1.657.76 1.657 1.69 0 .928-.746 1.688-1.657 1.688-.91 0-1.648-.76-1.648-1.689s.737-1.688 1.648-1.688zM.978.716a.82.82 0 011.127.326L4.96 6.255v7.95c0 .46.371.838.831.838h14.414c.458 0 .828.378.828.844 0 .466-.37.844-.828.844H5.791c-1.373 0-2.488-1.13-2.488-2.527v-7.51L.66 1.865A.853.853 0 01.979.715z\" fill=\"currentColor\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});