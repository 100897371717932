define("ember-slugify/helpers/remove-diacritics", ["exports", "@ember/component/helper", "ember-slugify"], function (_exports, _helper, _emberSlugify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function removeDiacritics(args) {
    let [string] = args;
    return (0, _emberSlugify.removeDiacritics)(string);
  }
  var _default = (0, _helper.helper)(removeDiacritics);
  _exports.default = _default;
});