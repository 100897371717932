define("ember-svg-jar/inlined/save", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9.468.158c.363 0 .71.146.963.405l2.027 2.073c.246.252.384.59.384.942v8.917c0 .744-.603 1.347-1.347 1.347H1.558A1.347 1.347 0 01.21 12.495V1.505C.21.761.814.158 1.558.158h7.91zM6.526 7.684c-1.162 0-2.105.92-2.105 2.053s.943 2.052 2.105 2.052c1.163 0 2.106-.919 2.106-2.052 0-1.134-.943-2.053-2.106-2.053zm3.51-2.052H3.017V2.895h7.017v2.737z\" fill=\"currentColor\" fill-rule=\"nonzero\" opacity=\".75\"/>",
    "attrs": {
      "width": "13",
      "height": "14",
      "viewBox": "0 0 13 14",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});